import App from "srs.sharedcomponents/lib/esm/App";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  ApplicationInsights,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";
import { env } from "srs.sharedcomponents/lib/esm/api/env";
import { store } from "srs.sharedcomponents/lib/esm/redux/store";
import coreSrsPrimaryOverride from "./i18n/coreSrsPrimaryOverride.json";
import { useTranslation } from "react-i18next";
import { i18nNamespaces as namespace } from "srs.sharedcomponents/lib/esm/i18n/i18n";
import { useDispatch } from "react-redux";
import { useAppSelector } from "srs.sharedcomponents/lib/esm/redux/hooks";
import { setFooterLinks } from "srs.sharedcomponents/lib/esm/redux/slices/footerLinksSlice";
import RouterProvider from "srs.sharedcomponents/lib/esm/components/context/router/RouterProvider";
import routerConfig from "srs.sharedcomponents/lib/esm/components/config/routes";

interface IAppWrapper {
  appInsights: ApplicationInsights;
}

const AppWrapper = ({ appInsights }: IAppWrapper) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n } = useTranslation();
  const footerLinks = useAppSelector((state) => state.footerLinks.links);
  const welcome = useAppSelector((state) => state.welcome);

  Object.entries(coreSrsPrimaryOverride).forEach(([locale, entity]) => {
    Object.entries(entity).forEach(([key, value]) => {
      i18n.addResource(locale, namespace.coreSrsPrimary, key, value);
    });
  });
  useEffect(() => {
    const pageInfo: IPageViewTelemetry = {
      name: location?.pathname,
      uri: window?.location.href,
      isLoggedIn: !!store.getState().user?.user?.token,
    };
    if (env.APPLICATIONINSIGHTS_CONNECTION_STRING) {
      appInsights.trackPageView(pageInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    const newFooterLinks = footerLinks;
    const newLinks = [...newFooterLinks];

    dispatch(setFooterLinks(newLinks));
  }, [welcome.welcome?.cmsBaseUrl]);
  return (
    <RouterProvider routesConfig={routerConfig}>
      <App />;
    </RouterProvider>
  );
};

export default AppWrapper;
