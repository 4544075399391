import { componentsConfig as defaultConfig } from "srs.sharedcomponents/lib/esm/components/config";
import WelcomeBuilder from "srs.sharedcomponents/lib/esm/components/organisms/Welcome/BuilderContent/WelcomeBuilder"
import FooterBuilderComponent from "srs.sharedcomponents/lib/esm/components/organisms/Footer/FooterBuilder/FooterBuilder"
import PlanningListComponent from "srs.sharedcomponents/lib/esm/components/planninglist/PlanningList"
//import DealerProfileComponent from "srs.sharedcomponents/lib/esm/components/dealerProfile/DealerProfile"

const componentsConfig = {
    ...defaultConfig,
    WelcomeContent: WelcomeBuilder,
    FooterContent: FooterBuilderComponent,
    PlanningList: PlanningListComponent,
    //DealerProfile: DealerProfileComponent
};
export { componentsConfig };